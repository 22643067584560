<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增Banner"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="Banner名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入Banner名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="图片">
          <span
            class="tip-text"
          >支持png/jpg图片格式，建议尺寸宽高比350:180px，最多1张
          </span>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png"
            :max-count="1"
            @change="handleUploadImage"
            v-decorator="['attachments',{
              rules:[
                { required: true, message: '请上传图片' },
                {validator: checkAttachments}
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="是否为外部链接">
          <a-checkbox
            @change="changeIsWebUrl"
            v-decorator="['is_web_url', {
              initialValue: false,
            }]"
          />
        </a-form-item>
        <a-form-item label="链接路径">
          <a-input
            v-decorator="['link_url', {
              normalize: this.$lodash.trim,
              rules: [
                { required: isWebUrl, message: '请输入链接路径' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import UploadImage from '@/components/Upload/Image'
import { createBanner } from '@/api/banner'

export default {
  name: 'NewBanner',
  components: {
    UploadImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'banner_new' }),
      submitting: false,
      isAddWatermark: false,
      attachments: [], // 图片
      isWebUrl: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    changeIsWebUrl(e) {
      this.isWebUrl = e.target.checked
    },
    handleUploadImage(fileList) {
      this.attachments = fileList
    },
    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            attachments: this.$lodash.map(this.attachments, 'response')
          }
          createBanner(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
