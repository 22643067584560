import request from '@/utils/request'

// 上传需要的token
export function getUploadToken() {
  return request({
    url: `/upload/token`,
    method: 'get'
  })
}

// 上传需要的带水印token
export function getUploadWatermarkToken() {
  return request({
    url: `/upload/watermark_token`,
    method: 'get'
  })
}

// 获取资源链接
export function getResourceURL(params) {
  return request({
    url: `/upload/resource_url`,
    method: 'get',
    params: params
  })
}
