var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"新增Banner"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Banner名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入Banner名称' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入Banner名称' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"图片"}},[_c('span',{staticClass:"tip-text"},[_vm._v("支持png/jpg图片格式，建议尺寸宽高比350:180px，最多1张 ")]),_c('upload-image',{directives:[{name:"decorator",rawName:"v-decorator",value:(['attachments',{
            rules:[
              { required: true, message: '请上传图片' },
              {validator: _vm.checkAttachments}
            ]
          }]),expression:"['attachments',{\n            rules:[\n              { required: true, message: '请上传图片' },\n              {validator: checkAttachments}\n            ]\n          }]"}],attrs:{"is-add-watermark":_vm.isAddWatermark,"accept":".jpg,.jpeg,.png","max-count":1},on:{"change":_vm.handleUploadImage}})],1),_c('a-form-item',{attrs:{"label":"是否为外部链接"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_web_url', {
            initialValue: false,
          }]),expression:"['is_web_url', {\n            initialValue: false,\n          }]"}],on:{"change":_vm.changeIsWebUrl}})],1),_c('a-form-item',{attrs:{"label":"链接路径"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['link_url', {
            normalize: this.$lodash.trim,
            rules: [
              { required: _vm.isWebUrl, message: '请输入链接路径' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['link_url', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: isWebUrl, message: '请输入链接路径' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }